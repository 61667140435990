const BaseURL = 'https://plei-end.melian.me/api';

const titles = {
  siteName: 'Programa de Liderazgo, Emprendimiento e Innovación PLEI',
}

const logos = {
  logoTopbar: 'logo_plei.png',
  logoLogin: 'plei-logo.png',
  backgroundLogin: 'background_desktop.jpg',
}
export { 
  BaseURL,
  titles,
  logos
}
